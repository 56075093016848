import { Message } from 'primeng/api';

export class ToasterHelper {
  private static _defaultErrorMessage = 'An error occurred!';
  private static _defaultSuccessMessage = 'Success!';

  static errorMessage(message?: string): Message {
    return {
      severity: 'error',
      detail: message ? message : this._defaultErrorMessage,
      icon: 'pi-exclamation-triangle',
      life: 5000,
    };
  }
  static successMessage(message?: string): Message {
    return {
      severity: 'success',
      detail: message ? message : this._defaultSuccessMessage,
      icon: 'pi-check-circle',
    };
  }
}
